import {PaletteColorOptions, createTheme} from '@mui/material';
import IconUnchecked from '../../asset/icons/Icon_unchecked_box.svg';
import IconChecked from '../../asset/icons/icon_checked_box.svg';
// Tool to create MUI theme https://zenoo.github.io/mui-theme-creator

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: PaletteColorOptions;
  }

  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
  }
  interface TypographyVariants {
    italic: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    italic?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    italic: true;
  }
}

export const darkTheme = createTheme({
  typography: {
    italic: {
      fontStyle: 'italic',
    },
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#1B2338',
      paper: 'rgb(10, 25, 41)',
    },
    text: {
      primary: '#e6e6e6',
    },
    primary: {
      main: '#0087c4',
      dark: '#0f1526',
      light: '#0087c4',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#1E3262',
      dark: '#0354a2',
      light: '#1e3262',
      contrastText: '#ffffff',
    },
    error: {
      main: '#DD0000',
      dark: '#461212',
      light: '#dd0000',
    },
    success: {
      main: '#4EA851',
    },
    info: {
      main: '#0454A3',
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: '24px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          border: '1px solid var(--gray-opacity-variant2)',
          borderRadius: '12px',
          '& .MuiTypography-root': {
            margin: '0',
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiPagination: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
        disablePadding: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          minHeight: '60px !important',
          position: 'sticky',
          top: '0',
          zIndex: '1',
          '& .MuiTableCell-root': {
            fontSize: '16px',
            color: 'var(--text-white-50)',
          },
          MuiTableCell: {
            padding: '40px !important',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& > tr': {
            borderBottom: `2px dashed var(--gray-opacity-variant2) !important`,
            minHeight: '60px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none',
          padding: '12px 0',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'medium',
      },
      styleOverrides: {
        root: {
          fontSize: '16px',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
        },
        sizeSmall: {
          height: '30px',
          minWidth: 'auto',
          img: {
            width: '18px',
            height: '18px',
          },
        },
        sizeMedium: {
          height: '40px',
        },
        sizeLarge: {
          height: '50px',
        },

        contained: {
          minWidth: '150px',
          '&.MuiButton-containedPrimary': {
            border: '1px solid var(--blue-variant1)',
            ':hover': {
              border: '1px solid var(--blue-variant1)',
            },
          },
          '&.MuiButton-containedSecondary': {
            border: '1px solid var(--blue_variant38)',
            '&:hover': {
              border: '1px solid var(--blue_variant39)',
            },
          },
        },
        outlined: {
          minWidth: '150px',
          border: '1px solid var(--blue-variant1) !important',
          ':hover': {
            background: 'var(--blue-variant1) !important',
            border: '1px solid var(--blue-variant1) !important',
          },
          '&.MuiButton-outlinedSizeSmall': {
            fontSize: '14px',
            background: 'var(--blue-variant4)',
            border: '1px solid var(--gray-variant4) !important',
            '&:hover': {
              border: '1px solid var(--gray-variant4) !important',
              background: 'var(--blue-variant7) !important',
            },
          },
          '&.MuiButton-outlinedError': {
            background: `var(--red_variant1) !important`,
            border: `1px solid var(--red_variant2)`,
            '&:hover': {
              background: `var(--red_variant2) !important`,
            },
          },
          '&.MuiButton-outlinedInfo': {
            background: '#0F1526 !important',
            border: '1px solid #818181 !important',
            color: '#818181 !important',
            '&:hover': {
              background: '#080c17 !important',
              border: '1px solid #818181 !important',
            },
          },
        },
        text: {
          color: `var(--blue-variant1)`,
          '&:hover': {
            color: `var(--text-white) !important`,
          },
          '&.MuiButton-textError': {
            color: 'var(--red_variant4) !important',
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          borderRadius: '5px',
          padding: '0 !important',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
          '&.Mui-disabled': {
            opacity: '0.5 !important',
          },
          '&.MuiIconButton-colorPrimary': {
            border: '1px solid var(--gray-variant4) !important',
            backgroundColor: '#0F1526',
            '&:hover': {
              border: '1px solid var(--gray-variant4) !important',
              background: 'var(--blue-variant7) !important',
            },
          },
          '&.MuiIconButton-colorPrimary.btnactive,&.MuiIconButton-colorPrimary.btnactive:hover':
            {
              backgroundColor: 'var(--blue-variant1) !important',
              borderColor: 'var(--blue-variant1) !important',
            },
          '&.MuiIconButton-colorSecondary': {
            border: '1px solid #D9D9D9 !important',
            background: '#15384C !important',
            '&:hover': {
              border: '1px solid var(--gray-variant4) !important',
              backgroundColor: '#0F1526',
            },
          },
          '&.MuiIconButton-colorError': {
            background: `var(--red_variant1) !important`,
            border: `1px solid var(--red_variant2)`,
            '&:hover': {
              background: `var(--red_variant2) !important`,
            },
          },
          '&.MuiIconButton-colorSuccess': {
            background: `#154C19 !important`,
            border: `1px solid #57912E !important`,
            '&:hover': {
              background: `#154C19 !important`,
              border: `1px solid #154C19 !important`,
            },
          },
        },

        sizeSmall: {
          height: '30px',
          width: '30px',
          minHeight: '30px',
        },
        sizeMedium: {
          height: '40px',
          width: '40px',
          minHeight: '40px',
        },
        sizeLarge: {
          height: '50px',
          width: '50px',
          minHeight: '50px',
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent !important',
          },
          '& .MuiTouchRipple-root': {
            display: 'none !important',
          },
          '& img': {
            width: 20,
            height: 20,
          },
        },
        sizeSmall: {
          '& img': {
            width: 10,
            height: 10,
          },
        },
      },
      defaultProps: {
        size: 'medium',
        icon: <img src={IconUnchecked} alt='unchecked' />,
        checkedIcon: <img src={IconChecked} alt='checked' />,
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
      styleOverrides: {
        root: {
          color: `var(--text-white-50)`,
          fontStyle: 'italic',
          margin: '0',
          paddingTop: '10px',
          fontSize: '13px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: `var(--text-white) !important`,
          minHeight: '40px',
          borderRadius: '5px !important',
          borderColor: `var(--gray-variant8)`,
          'label + &': {
            borderRadius: '100px !important',
            minHeight: '40px !important',
          },
        },
      },
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused, &.MuiInputLabel-shrink': {
            color: `var(--text-white-50)`,
          },
        },
      },
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent !important',
          },
          '& .MuiTouchRipple-root': {
            display: 'none !important',
          },
          '& img': {
            width: 20,
            height: 20,
          },
        },
        sizeSmall: {
          '& img': {
            width: 10,
            height: 10,
          },
        },
      },
      defaultProps: {
        size: 'medium',
        icon: <img src={IconUnchecked} alt='unchecked' />,
        checkedIcon: <img src={IconChecked} alt='checked' />,
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 8,
        },
        action: {
          marginRight: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 8,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthXs: {
          maxWidth: '428px',
          '& .MuiDialogTitle-root': {
            textAlign: 'center !important',
            border: 'none !important',
            '& .MuiTypography-root': {
              marginLeft: '44px',
            },
          },
          '& .MuiDialogTitle-root.titleborder': {
            borderBottom: '1px solid var(--gray-opacity-variant4) !important',
          },
        },
        paperWidthSm: {
          maxWidth: '584px',
          '& .MuiDialogTitle-root': {
            textAlign: 'center !important',
            border: 'none !important',
            '& .MuiTypography-root': {
              marginLeft: '44px',
            },
          },
          '& .MuiDialogTitle-root.titleborder': {
            borderBottom: '1px solid var(--gray-opacity-variant4) !important',
          },
        },
        paperWidthMd: {
          maxWidth: '740px',
        },
        paperWidthLg: {
          maxWidth: '896px',
          maxHeight: '722px',
          height: '100%',
        },
        paperWidthXl: {
          maxWidth: '1208px',
          maxHeight: '722px',
          height: '100%',
        },
        root: {
          '& .MuiOutlinedInput-root': {
            background: 'var(--blue_variant41)',
            width: '100%',
          },
        },
        paper: {
          backgroundColor: `var(--blue-variant17) !important`,
          backgroundImage: 'unset',
          borderRadius: '10px',
          maxHeight: 'calc(100% - 60px)',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          minHeight: '68px',
          padding: '12px 15px',
          borderBottom: '1px solid var(--gray-opacity-variant4)',
          '& .alignCenter': {
            textAlign: 'center',
            marginLeft: '44px !important',
          },
          '& .MuiTypography-body1': {
            fontSize: '18px !important',
            fontWeight: 'bold',
            lineHeight: '1.5 !important',
            width: 'calc(100% - 30px)',
            margin: '0',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            background: 'transparent',
            boxShadow: 'none',
          },
          padding: '30px !important',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '10px 30px 30px 30px',
          '& .MuiButtonBase-root': {
            marginLeft: '18px',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'dense',
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'transparent',
      },
    },

    MuiTypography: {
      defaultProps: {
        fontFamily: 'poppins',
      },
    },

    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: 'var(--text-white-50)',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: `var(--gray-variant8)`,
          fontSize: '16px',
          borderTop: '1px solid #fffff80',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 255, 0.1)',
          },
          paddingTop: '5px',
        },
        icon: {
          width: '35px !important',
          height: '38px !important',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          color: `var(--gray-variant8)`,
          border: '1px solid var(--gray-variant8)',
          borderRadius: '50px',
          padding: '8px 10px 6px 13px',
          fontSize: '16px',
          display: 'flex',
          alignItems: 'center',
          minWidth: '28px !important',
        },
        selectIcon: {
          color: 'white',
          right: '6px',
        },
        selectLabel: {
          color: '#ffffff50',
          fontSize: '16px',
          marginRight: '10px',
        },
        menuItem: {
          color: '#ffffff50',
        },
        displayedRows: {
          color: '#ffffff50',
          marginLeft: '0px',
        },
        input: {
          marginRight: '20px',
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          border: '2px solid white',
          backgroundColor: 'blue',
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-paper': {
            border: '1px solid var(--gray-variant9) !important',
            marginTop: '5px !important',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        clearIndicator: {
          zIndex: '2',
        },
      },
    },
  },
});

export const RequestTheme = createTheme(darkTheme, {
  palette: {
    mode: 'dark',
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#2a2e33',
          marginBottom: '1em',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: '#414750',
          padding: 8,
        },
        title: {
          fontSize: '1.2rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 8,
          '&:last-child': {
            paddingBottom: 8,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          root: {
            '&:focus-within': {
              outline: 'none !important',
              background: '#00ffff !important',
            },
          },
        },
      },
    },
  },
});
