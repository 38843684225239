import React from 'react';
import {useSelector} from 'react-redux';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Backdrop, CircularProgress, Box} from '@mui/material';
import {RootState} from '../../store/configstore';
import {isAuthenticatedSelector} from '../../store/Slices/userSlice';
import {
  companyIsImpersonateSelector,
  isCompanySuspendedSelector,
} from '../../store/Slices/companySlice';
import Header from './Header';
import AccountSuspendedDialog from '../../pages/staff-landing-page/AccountSuspendedDialog';

export default function AuthUsers({withHeader = true}) {
  const navigate = useNavigate();
  const location = useLocation();
  const {loader} = useSelector((state: RootState) => state.common);
  const isLogin = useSelector(isAuthenticatedSelector);
  const isSuspendedCompany = useSelector(isCompanySuspendedSelector);
  const isImpersonatedUser = useSelector(companyIsImpersonateSelector);

  React.useEffect(() => {
    if (!isLogin) {
      navigate('/sign-in', {state: {redirectTo: location.pathname}});
    }
  }, [navigate, isLogin, location.pathname]);

  return (
    <>
      <BackDropCmpt loader={loader} />
      {withHeader && <Header />}
      {withHeader ? (
        <Box paddingTop='92px' className='h-full'>
          {isSuspendedCompany && !isImpersonatedUser ? (
            <AccountSuspendedDialog />
          ) : (
            <React.Suspense fallback={<BackDropCmpt />}>
              <Outlet />
            </React.Suspense>
          )}
        </Box>
      ) : isSuspendedCompany && !isImpersonatedUser ? (
        <AccountSuspendedDialog />
      ) : (
        <React.Suspense fallback={<BackDropCmpt />}>
          <Outlet />
        </React.Suspense>
      )}
    </>
  );
}

export const BackDropCmpt = ({loader = true}) => {
  return (
    <Backdrop
      sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
      open={loader}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};
