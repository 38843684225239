import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {useSelector} from 'react-redux';
import {useQueryClient} from '@tanstack/react-query';
import {
  Box,
  Button,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Autocomplete,
  Typography,
  Grid,
  Tooltip,
  Stack,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
  GridSortModel,
  GridActionsCellItem,
  GridRowModes,
} from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  DateRange,
  DateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import InfoIcon from '@mui/icons-material/Info'; // Import the icon you want to use
import {
  IListQueryParms,
  IMsgRequestCust,
  queryKeyMessageRequestList,
  useRqGetMessageList,
} from '../../../../react_query/messages/MessagesByCmpId';
import {DataGridCustPagination} from '../../../../components/utlis/DataGrid/Pagination';
import {useStoreSelector} from '../../../../store/configstore';

import {
  companyIdSelector,
  isGTSelector,
  isNRorSZSelector,
  productLevelSelector,
} from '../../../../store/Slices/companySlice';
import {useUserCanAny} from '../../../../hooks/useUserCan';
import {apiPatch, apiPost} from '../../../../axiosConfig';
import DialogApiRequestStatus from '../../../../components/dialogs/DialogApiRequestStatus';
import MusicPreviewBtn from '../../../../components/music/MusicPreviewBtn';
import {useRqVoiceArtists} from '../../../../react_query/users/CompanyContacts';
import {useRqBaseMessageVoiceArtist} from '../../../../react_query/base-messages/BaseMessageRequestQueries';
import {
  getSeasonUrl,
  useRqListSeasons,
} from '../../../../react_query/seasons/ListSeasons';
import {ISeason} from './BaseMessagesReducer';
import DialogConfirmDeletion from '../../../../components/dialogs/DialogConfirmDeletion';
import {useRqAllSports} from '../../../../react_query/event_types/GetAll';
import {IEventType} from '../../../../dto/EventType.dto';
import GridFilterHeader from '../../../../components/header/GridFilterHeader';
import IconAdd from '../../../../asset/icons/icon_messaging.svg';
import CustomNoRowsOverlay from '../../../../components/utlis/DataGrid/CustomNoRowsOverlay';
import IconAddEvent from '../../../../asset/icons/icon_addsong.svg';
import {IEsmStatus} from '../../../../dto/EventSpecificMessageRequest.dto';
import IconEditButton from '../../../../components/Button/IconEditButton';
import IconDeleteButton from '../../../../components/Button/IconDeletebutton';
import CreateButton from '../../../../components/Button/CreateButton';
import BaseMsgDataGridItemAction from './BaseMsgDataGridItemAction';
import {isInternalSelector} from '../../../../store/Slices/userSlice';
import {DialogClose} from '../../../../components/dialogs/DialogFrame/DialogClose';
import {ICompanySchool} from '../../../../dto/CompanySchool.dto';
import {IStation} from '../../../../dto/Station.dto';
import BaseMessageCreate from './BaseMessageCreate';
import {UserCanAny} from '../../../../components/UserCan';
import DownloadButton from '../../../../components/Button/DownloadButton';

const defaultEventSelectAll = {
  id: -1,
  event_name: 'All Sports',
  event_short_name: 'All Sports',
};

const defaulSeasonSelectAll = {
  id: -1,
  season_name: 'All Seasons',
};

const BaseMsgDataGrid = () => {
  const queryClient = useQueryClient();
  const isGT = useSelector(isGTSelector);
  const isNRSZ = useSelector(isNRorSZSelector);
  const [status, setStatus] = useState<IEsmStatus>('Active');
  const companyId = useStoreSelector(companyIdSelector);
  const [apiState, setApiState] = useState({status: '', error: null});
  const [row, setRow] = useState<GridRowModel>([]);
  const [pagination, setPagination] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [open, setOpen] = useState(false);
  const [openBaseModal, setOpenBaseModal] = useState<boolean>(false);
  const [seasonOpen, setSeasonOpen] = useState(false);
  const [search, setSearch] = React.useState('');
  const [query, setQuery] = React.useState<IListQueryParms>({
    per_page: 10,
    page: 1,
    search: '',
    status: '1',
  });
  const [selectedEvent, setSelectedEvent] = useState<IEventType>(
    defaultEventSelectAll as IEventType,
  );
  const [selectedSeason, setSelectedSeason] = useState<ISeason>(
    defaulSeasonSelectAll,
  );
  useEffect(() => {
    setQuery(prev => {
      if (selectedEvent.id === -1) {
        const eventRemovedQuery = structuredClone(prev);
        delete eventRemovedQuery.event_id;
        return eventRemovedQuery;
      } else {
        return {...prev, event_id: selectedEvent.id};
      }
    });
  }, [selectedEvent.id]);

  useEffect(() => {
    setQuery(prev => {
      if (selectedSeason.id === -1) {
        const seasonRemovedQuery = structuredClone(prev);
        delete seasonRemovedQuery.season_id;
        return seasonRemovedQuery;
      } else {
        return {...prev, season_id: selectedSeason.id};
      }
    });
  }, [selectedSeason.id]);

  const canEdit = useUserCanAny(
    'is-admin,partner,base-message:view,base-message:delete,convert-base-message',
  );
  const slugEditable = useUserCanAny('is-admin,base-message:update');
  React.useEffect(() => {
    sessionStorage.setItem('status', 'active');
  }, []);

  React.useEffect(() => {
    setQuery(prev => {
      const newStatus = status === 'Active' ? '1' : '0';
      return {
        ...prev,
        page: pagination.page + 1,
        per_page: pagination.pageSize,
        search: search,
        status: newStatus,
      };
    });
  }, [companyId, pagination, search, status]);
  const activeState = !!(Number(companyId) && Number(pagination.page + 1));

  const {data, isLoading} = useRqGetMessageList(
    Number(companyId),
    query,
    'base-message',
    activeState,
  );
  const columns: GridColDef[] = useMemo(() => {
    const baseColumns: GridColDef[] = [
      {
        field: 'preview',
        type: 'actions',
        headerName: 'Play',
        width: 90,
        align: 'center',
        renderCell: (params: GridRenderCellParams) =>
          params.row?.media_track ? (
            <Stack direction='row' spacing={2}>
              <MusicPreviewBtn
                id={params.row.id}
                url={params.row.media_track?.url}
                track_name={
                  params.row.media_track?.track_name || params.row.name
                }
                track_artist={
                  params.row.media_track?.track_artist || params.row.message
                }
              />
              <DownloadButton
                filename={params.row.media_track?.track_name || params.row.name}
                url={params.row.media_track?.url}
              />
            </Stack>
          ) : null,
      },
      {
        field: 'name',
        headerName: 'Message Name',
        hideable: false,
        flex: 2,
        renderCell: (params: GridRenderCellParams) => (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Tooltip title={params.row.message ? params.row.message : 'NA'}>
              <InfoIcon fontSize='small' style={{marginRight: 4}} />
            </Tooltip>
            {params.value || 'NA'}
          </div>
        ),
      },
      {
        field: 'slug',
        headerName: 'Slug Name',
        hideable: false,
        flex: 1,
        editable: slugEditable,

        renderCell: (params: GridRenderCellParams) => {
          return params.value === null || params.value === ''
            ? 'NA'
            : params.value;
        },
      },

      {
        field: 'stations',
        headerName: 'Station(s)',
        hideable: false,
        flex: 2,
        sortable: false,
        filterable: false,
        valueGetter: params =>
          params.row.stations
            .map((s: IStation) => s.label || s.name)
            .join(', '),
      },
      {
        field: 'created_at',
        headerName: 'Created Date',
        hideable: false,
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return dayjs(params.value).format('MM/DD/YYYY hh:mm:ss A');
        },
      },
      {
        field: 'actions',
        headerName: 'Action',
        type: 'actions',
        hideable: false,
        flex: 1,
        minWidth: 200,
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<IMsgRequestCust>) => {
          return <BaseMsgDataGridItemAction req={params.row} status={status} />;
        },
      },
    ];
    if (isGT) {
      baseColumns.splice(3, 0, {
        field: 'schools',
        headerName: 'School(s)',
        hideable: false,
        flex: 2,
        sortable: false,
        filterable: false,
        valueGetter: params =>
          params.row.schools.map((s: ICompanySchool) => s.name).join(', '),
      });
    }
    if (isNRSZ) {
      baseColumns.splice(5, 0, {
        field: 'seasons',
        headerName: 'Season(s)',
        hideable: false,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value.length > 0
            ? params.value.join(',')
            : params.row.is_all_seasons === 1
            ? 'Year Around'
            : '';
        },
      });
    } else {
      baseColumns.splice(5, 0, {
        field: 'events',
        headerName: 'Events(s)',
        hideable: false,
        flex: 1,
        sortable: false,
        filterable: false,
      });
    }
    return baseColumns.map(column => ({
      ...column,
    }));
  }, [slugEditable, isNRSZ, status, isGT]);

  const handleStatus = (newValue: IEsmStatus) => {
    setStatus(newValue);
  };
  const handleSearch = useCallback((value: string) => {
    setSearch(value);
    setQuery(prev => ({...prev, search: value}));
  }, []);

  const handleActionButtonClick = () => {
    setOpenBaseModal(true);
  };
  const closeBaseModal = () => {
    setOpenBaseModal(false);
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    setApiState({status: 'pending', error: null});
    setRow(newRow);
    const payload = {
      slug: newRow.slug,
    };
    apiPatch(
      `/v1/company/${companyId}/message/base-message/${newRow.id}`,
      payload,
    )
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [queryKeyMessageRequestList(companyId, 'base-message')],
          refetchType: 'active',
        });
        setApiState({status: 'success', error: null});
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
    return newRow;
  };

  const handleSubmit = () => {
    processRowUpdate(row);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    setQuery(prev => {
      const updatedQuery = {
        ...prev,
        field: model[0]?.field,
        sort: model[0]?.sort,
      };
      return updatedQuery;
    });
  };
  const handleEdit = () => {
    setApiState({status: '', error: null});
  };

  const handleEventChange = useCallback(
    (value: IEventType) => {
      if (setSelectedEvent) {
        setSelectedEvent(value);
      }
    },
    [setSelectedEvent],
  );

  const handleSeasonChange = useCallback(
    (value: ISeason) => {
      if (setSelectedSeason) {
        setSelectedSeason(value);
      }
    },
    [setSelectedSeason],
  );

  return (
    <Box className='GridTableList'>
      <GridFilterHeader
        handleSearch={handleSearch}
        handleStatus={handleStatus}
        handleActionButtonClick={handleActionButtonClick}
        status={status}
        IconAdd={IconAdd}
        query={query}
        handleEventChange={handleEventChange}
        selectedEvent={selectedEvent}
        selectedSeason={selectedSeason}
        handleSeasonChange={handleSeasonChange}
        eventName='base-message'
        bmvOpen={open}
        setBmvOpen={setOpen}
        bmsOpen={seasonOpen}
        setBmsOpen={setSeasonOpen}
      />

      <Box sx={{height: 'calc(100vh - 250px)'}}>
        <DataGrid
          disableColumnSelector
          disableColumnMenu
          className='custom-data-grid cmc_new_grid h-654 text-white'
          rows={data?.data ?? []}
          columns={columns}
          loading={isLoading}
          rowCount={data?.meta?.total ?? 0}
          paginationModel={pagination}
          onSortModelChange={handleSortModelChange}
          pageSizeOptions={[10, 25, 50]}
          paginationMode='server'
          onPaginationModelChange={setPagination}
          processRowUpdate={processRowUpdate}
          disableRowSelectionOnClick
          slots={{
            pagination: DataGridCustPagination,
            noRowsOverlay: () => (
              <CustomNoRowsOverlay
                imageSrc={IconAdd}
                message='No Base messages available'
                buttonText='Create New'
                IconButton={IconAddEvent}
                onButtonClick={handleActionButtonClick}
              />
            ),
          }}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
          }}
          columnVisibilityModel={{
            actions: canEdit,
          }}
        />
      </Box>

      {openBaseModal && <BaseMessageCreate handleCancel={closeBaseModal} />}

      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={handleEdit}
          onClose={handleEdit}
        />
      )}
    </Box>
  );
};

export default BaseMsgDataGrid;

export const BaseMessageVoice = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) => {
  const companyId = useStoreSelector(companyIdSelector);
  const isPartner = !useSelector(isInternalSelector);
  const [checked, setChecked] = useState<number[]>([]);
  const [apiState, setApiState] = useState({status: '', error: null});
  const productLevel = useSelector(productLevelSelector);
  const isLevel1 = productLevel.includes('Level 1');
  const {data: VoiceArtistData, isFetching: voiceArtistIsFetching} =
    useRqVoiceArtists(companyId, {type: 'djvp'});
  const {data: baseVoice, isFetching: baseVoiceIsFetching} =
    useRqBaseMessageVoiceArtist(companyId);

  useEffect(() => {
    if (baseVoice && !baseVoiceIsFetching) {
      const selectedIds = baseVoice.map(val => val.id);
      setChecked(selectedIds);
    }
  }, [baseVoice, baseVoiceIsFetching]);

  const handleClose = () => {
    setOpen(false);
    if (baseVoice && !baseVoiceIsFetching) {
      const selectedIds = baseVoice.map(val => val.id);
      setChecked(selectedIds);
    }
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (isLevel1 && newChecked.length <= 1) {
      setChecked(newChecked);
    } else if (!isLevel1 && newChecked.length <= 3) {
      setChecked(newChecked);
    }
  };

  const handleSubmit = useCallback(() => {
    setApiState({status: 'pending', error: null});
    const fd = new FormData();
    checked.forEach((val, index) => {
      fd.append(`voice_artists[${index}]`, val.toString());
    });
    apiPost(`v1/company/${companyId}/message/base-message/voice-artist`, fd)
      .then(() => {
        setOpen(false);
        setApiState({status: '', error: null});
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
  }, [checked, companyId, setOpen]);

  const handleEdit = useCallback(() => {
    setApiState({
      status: '',
      error: null,
    });
  }, []);

  const isSubmitEnabled = useCallback(() => {
    if (isLevel1) {
      return checked.length === 1;
    } else {
      return checked.length === 3;
    }
  }, [checked.length, isLevel1]);

  return (
    <>
      <Dialog open={open} maxWidth='sm' fullWidth onClose={handleClose}>
        <DialogTitle>
          <Typography>Voice Personnel</Typography>
          <DialogClose onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          {isPartner ? (
            <List>
              {VoiceArtistData && !voiceArtistIsFetching
                ? VoiceArtistData.map(value => {
                    const labelId = `checkbox-list-label-${value}`;
                    if (checked.indexOf(value.id) !== -1) {
                      return (
                        <ListItem key={value.id} disablePadding>
                          <ListItemButton role={undefined} dense>
                            <ListItemIcon>
                              <Checkbox
                                edge='start'
                                checked={checked.indexOf(value.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{'aria-labelledby': labelId}}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.name} />
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                    return null;
                  })
                : null}
            </List>
          ) : (
            <List>
              {VoiceArtistData && !voiceArtistIsFetching
                ? VoiceArtistData.map(value => {
                    const labelId = `checkbox-list-label-${value}`;

                    return (
                      <ListItem key={value.id} disablePadding>
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggle(value.id)}
                          dense>
                          <ListItemIcon>
                            <Checkbox
                              edge='start'
                              checked={checked.indexOf(value.id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{'aria-labelledby': labelId}}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={value.name} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })
                : null}
            </List>
          )}
          {(VoiceArtistData?.length === 0 && !voiceArtistIsFetching) ||
          (isPartner && checked.length === 0) ? (
            <Typography className='text-white text-center'>
              No voice artist assigned
            </Typography>
          ) : null}
        </DialogContent>
        {!isPartner &&
        VoiceArtistData?.length !== 0 &&
        !voiceArtistIsFetching ? (
          <DialogActions className='px-8 pb-8'>
            <Button
              onClick={handleSubmit}
              autoFocus
              variant='contained'
              disabled={!isSubmitEnabled()}>
              Submit
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={handleEdit}
          onClose={handleEdit}
        />
      )}
    </>
  );
};

export const BaseMessageSeason = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) => {
  const companyId = useStoreSelector(companyIdSelector);
  const [apiState, setApiState] = useState({status: '', error: null});
  const isNRSZ = useSelector(isNRorSZSelector);
  const queryClient = useQueryClient();
  const [name, setName] = useState('');
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [dateRowRange, setDateRowRange] = useState<DateRange<Dayjs>>([
    null,
    null,
  ]);
  const [delId, setDelId] = React.useState<number | null>(null);
  const [seasonName, setSeasonName] = useState('');
  const [rows, setRows] = useState<ISeason[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );
  const [editableRowId, setEditableRowId] = useState(0);
  const {data: seasonsData, isFetching: seasonsFetching} = useRqListSeasons(
    Number(companyId),
    Boolean(companyId) && isNRSZ,
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRowModesModel(prev => {
      const newPrev: {
        [key: string]: {mode: GridRowModes; ignoreModifications: boolean};
      } = {};
      for (let key in prev) {
        newPrev[key] = {mode: GridRowModes.View, ignoreModifications: true};
      }
      return {...newPrev};
    });
  };
  const handleSubmit = () => {
    setApiState({status: '', error: null});
    const payload = {
      name,
      start_date: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : '',
      end_date: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : '',
    };
    apiPost(`v1/company/${companyId}/seasons`, payload)
      .then(() => {
        setApiState({status: 'success', error: null});
        setName('');
        setDateRange([null, null]);
        queryClient.invalidateQueries({
          queryKey: [getSeasonUrl(companyId)],
          refetchType: 'active',
        });
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
  };
  const handleEdit = useCallback(() => {
    setApiState({
      status: '',
      error: null,
    });
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  useEffect(() => {
    if (seasonsData && !seasonsFetching) {
      setRows(seasonsData);
    }
  }, [seasonsData, seasonsFetching]);

  const handleOnChange = useCallback((newValue: DateRange<dayjs.Dayjs>) => {
    setDateRowRange(newValue);
  }, []);

  const columns = [
    {
      field: 'season_name',
      headerName: 'Season Name',
      sortable: false,
      editable: true,
      flex: 1,
    },
    {
      field: 'seasonTimeline',
      headerName: 'Season Timeline',
      flex: 1,
      sortable: false,
      editable: true,
      renderEditCell: (params: GridRenderCellParams) => {
        return (
          <DateRangePicker
            sx={{width: '100%'}}
            value={
              dateRowRange[0]
                ? dateRowRange
                : [
                    params.row.start_date ? dayjs(params.row.start_date) : null,
                    params.row.end_date ? dayjs(params.row.end_date) : null,
                  ]
            }
            onChange={handleOnChange}
            minDate={dayjs().add(1, 'day')}
          />
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return `${dayjs(params.row.start_date).format('MM/DD/YYYY')} - ${dayjs(
          params.row.end_date,
        ).format('MM/DD/YYYY')}`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params: GridRenderCellParams) => {
        const id = params.row.id;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              title='Save'
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              title='Cancel'
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ];
        }

        return [
          <>
            <UserCanAny scope='is-admin,partner,season:update'>
              <IconEditButton
                onClick={handleEditClick(id)}
                aria-label='edit'
                title='Edit'
              />
            </UserCanAny>
            <UserCanAny scope='is-admin,partner,season:delete'>
              <IconDeleteButton
                onClick={handleDeleteClick(id, params.row.season_name)}
                aria-label='delete'
              />
            </UserCanAny>
          </>,
        ];
      },
    },
  ];

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setEditableRowId(Number(id));
    setRowModesModel(prev => {
      const newPrev: {
        [key: string]: {mode: GridRowModes; ignoreModifications: boolean};
      } = {};
      for (let key in prev) {
        newPrev[key] = {mode: GridRowModes.View, ignoreModifications: true};
      }
      return {...newPrev, [id]: {mode: GridRowModes.Edit}};
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({[id]: {mode: GridRowModes.View}});
  };

  const handleDeleteClick = (id: number, name: string) => () => {
    setDelId(id);
    setSeasonName(name);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      [id]: {mode: GridRowModes.View, ignoreModifications: true},
    });
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    setApiState({status: '', error: null});
    const payload = {
      name: newRow.season_name,
      start_date: dateRowRange[0]
        ? dateRowRange[0].format('YYYY-MM-DD')
        : newRow.start_date,
      end_date: dateRowRange[1]
        ? dateRowRange[1].format('YYYY-MM-DD')
        : newRow.end_date,
    };
    const updatedRow = {...newRow};
    apiPost(`v1/company/${companyId}/seasons/${newRow.id}?_method=PUT`, payload)
      .then(() => {
        setApiState({status: 'success', error: null});
        setDateRowRange([null, null]);
        setRowModesModel({});
        queryClient.invalidateQueries({
          queryKey: [getSeasonUrl(companyId)],
          refetchType: 'active',
        });
        setEditableRowId(0);
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <>
      <CreateButton title='Seasons' onClick={handleClickOpen} />
      <Dialog open={open} maxWidth='lg' fullWidth onClose={handleClose}>
        <DialogTitle>
          <Typography>Seasons</Typography>
          <DialogClose onClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <UserCanAny scope='is-admin,season:create'>
            <Grid container className='flex items-center mb-5 items-end'>
              <Grid item xs={4}>
                <Typography>Enter A New Season Name</Typography>
                <TextField
                  className='mb-0'
                  id='season-name-text'
                  placeholder='Season Name'
                  variant='outlined'
                  value={name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography>Choose the Season’s start & End</Typography>
                <DateRangePicker
                  className='mb-0'
                  slots={{field: SingleInputDateRangeField}}
                  value={dateRange}
                  onChange={setDateRange}
                  minDate={dayjs().add(1, 'day')}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSubmit}
                  disabled={
                    name === '' ||
                    dateRange[0] === null ||
                    dateRange[1] === null
                  }>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </UserCanAny>

          <Box height={500} width={'100%'} mt={2}>
            <UserCanAny scope='is-admin,season:list'>
              <DataGrid
                disableColumnSelector
                density='compact'
                disableColumnMenu
                rows={rows ?? []}
                columns={columns}
                isCellEditable={params => params.row.id === editableRowId}
                loading={seasonsFetching}
                pageSizeOptions={[5, 10, 15, 25]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                editMode='row'
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                disableRowSelectionOnClick
                getRowHeight={() => 'auto'}
                slots={{
                  pagination: DataGridCustPagination,
                }}
                slotProps={{
                  columnsPanel: {
                    disableHideAllButton: true,
                    disableShowAllButton: true,
                  },
                }}
              />
            </UserCanAny>
          </Box>
        </DialogContent>
      </Dialog>
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={handleEdit}
          onClose={handleEdit}
        />
      )}
      {delId ? (
        <DeleteSeasonPopup
          delId={delId}
          setDelId={setDelId}
          seasonName={seasonName}
        />
      ) : null}
    </>
  );
};

const DeleteSeasonPopup = ({
  delId,
  setDelId,
  seasonName,
}: {
  delId: number;
  setDelId: (val: null) => void;
  seasonName: string;
}) => {
  const [apiState, setApiState] = useState({status: '', error: null});

  const queryClient = useQueryClient();
  const companyId = useStoreSelector(companyIdSelector);
  const handleOnDelete = useCallback(() => {
    setApiState({status: '', error: null});
    apiPost(`v1/company/${companyId}/seasons/${delId}?_method=Delete`)
      .then(() => {
        setApiState({status: 'success', error: null});
        setDelId(null);
        queryClient.invalidateQueries({
          queryKey: [getSeasonUrl(companyId)],
          refetchType: 'active',
        });
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
  }, [companyId, delId, queryClient, setDelId]);

  if (
    apiState.status === 'pending' ||
    apiState.status === 'error' ||
    apiState.status === 'success'
  ) {
    return (
      <DialogApiRequestStatus
        apiState={apiState}
        onRetry={handleOnDelete}
        onEdit={() => setApiState({status: '', error: null})}
        onClose={() => {
          setApiState({status: '', error: null});
        }}
      />
    );
  }

  return (
    <DialogConfirmDeletion
      name={
        <span>
          <small>{seasonName}</small> from base message seasons list
        </span>
      }
      onDelete={handleOnDelete}
      onCancel={() => setDelId(null)}
      isPermanent
    />
  );
};

// Event filter.
export const EventsFilter = ({
  selectedEvent,
  setSelectedEvent,
  company_id,
}: {
  selectedEvent: IEventType;
  setSelectedEvent: (id: IEventType) => void;
  company_id: number;
}) => {
  const [events, setEvents] = useState<IEventType[]>([
    defaultEventSelectAll,
  ] as IEventType[]);
  const {data, isFetching, isError} = useRqAllSports(true, company_id);
  useEffect(() => {
    if (!isFetching && !isError && data) {
      setEvents(prev => [...prev, ...data]);
    }
  }, [isError, isFetching, data]);
  return (
    <Autocomplete
      id={`events-${selectedEvent.id}`}
      onChange={(_, value) => {
        const val = (value ?? defaultEventSelectAll) as IEventType;
        setSelectedEvent(val);
      }}
      value={selectedEvent}
      defaultValue={defaultEventSelectAll}
      options={events}
      getOptionLabel={option => option.event_name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <TextField {...params} id='text-field' placeholder='Sport/Event' />
      )}
    />
  );
};

//Choose Seasons
export const SeasonsFilter = ({
  selectedSeason,
  setSelectedSeason,
}: {
  selectedSeason: ISeason;
  setSelectedSeason: (val: ISeason) => void;
}) => {
  const companyId = useStoreSelector(companyIdSelector);
  const isNRSZ = useSelector(isNRorSZSelector);
  const [seasons, setSeasons] = useState<ISeason[]>([defaulSeasonSelectAll]);

  const {data} = useRqListSeasons(
    Number(companyId),
    Boolean(companyId) && isNRSZ,
  );
  useEffect(() => {
    if (data) {
      setSeasons([defaulSeasonSelectAll, ...data]);
    }
  }, [data]);

  return (
    <Autocomplete
      id='tags-outlined'
      onChange={(_, value) => {
        setSelectedSeason(value ?? defaulSeasonSelectAll);
      }}
      value={selectedSeason}
      defaultValue={defaulSeasonSelectAll}
      options={seasons ?? []}
      getOptionLabel={(option: ISeason) => {
        return option.season_name;
      }}
      renderInput={params => <TextField {...params} placeholder='Seasons' />}
    />
  );
};
