import {ContactMail, ContactPhone, Person} from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Link,
  Stack,
  DialogActions,
  Button,
} from '@mui/material';
import {useSelector} from 'react-redux';
import {companyPsmSelector} from '../../store/Slices/companySlice';
import {userCompaniesSelector} from '../../store/Slices/userSlice';
import {useState} from 'react';

const InfoRow = ({
  icon: Icon,
  label,
  value,
}: {
  icon: React.ElementType;
  label: string;
  value?: string;
}) => (
  <Stack direction='row' spacing={2} alignItems='center'>
    <Icon fontSize='small' />
    <Typography sx={{fontWeight: 'bold'}}>{label}:</Typography>
    <Typography>{value}</Typography>
  </Stack>
);

function AccountSuspendedDialog() {
  const psmInfo = useSelector(companyPsmSelector);
  const companies = useSelector(userCompaniesSelector);
  const [open, setOpen] = useState(true);
  return (
    <Dialog open={open} maxWidth='md'>
      <DialogTitle sx={{textAlign: 'center'}}>
        <Typography>
          <span style={{color: 'red', fontWeight: 'bold'}}>Attention</span> -{' '}
          <strong>Account Suspended</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1' gutterBottom>
          Your account has been suspended.
        </Typography>
        <Typography variant='body1' gutterBottom>
          Please contact the <strong>Partner Success Manager</strong> for
          further assistance.
        </Typography>

        <Stack spacing={2} sx={{my: 3}}>
          <InfoRow icon={Person} label='Name' value={psmInfo?.name} />
          <InfoRow icon={ContactMail} label='Email' value={psmInfo?.email} />
          <InfoRow
            icon={ContactPhone}
            label='Contact'
            value={psmInfo?.phone ?? '(903) 287-0770'}
          />
        </Stack>

        <Typography variant='body1' sx={{mt: 3}}>
          You can also reach out to our support team -{' '}
          <strong>(903) 287-0770</strong>
        </Typography>
        <Typography variant='body1'>
          Email -{' '}
          <Link href='mailto:support@neptunenow.com' underline='hover'>
            support@neptunenow.com
          </Link>
        </Typography>
      </DialogContent>
      {companies?.length > 1 &&
      companies?.some(c => !Boolean(c.suspended_at)) ? (
        <DialogActions>
          <Button variant='contained' onClick={() => setOpen(false)}>
            Choose Different Company
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}

export default AccountSuspendedDialog;
